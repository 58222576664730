<template>
  <div class="item">
    <div class="item__image col-6">
      <img :src="imageUrl" class="item__img " />
    </div>
    <div class="col-6 px-4">
      <div class="">
        <div>{{ nameObject.type + nameObject.name }}</div>
        <div class="text-secondary text-9">{{ nameObject.wear }}</div>
      </div>
      <div class="mt-4">
        <span>支付总额：</span>
        <span class="text-success text-12">${{ price }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ASSET_URL } from '@/config'
import { nameFilter } from '@/utils'

export default {
  name: 'LongStoreItem',
  props: {
    item: Object,
    price: String,
  },
  data: function() {
    return {}
  },
  computed: {
    imageUrl() {
      return ASSET_URL + this.item?.item?.image_url
    },
    nameObject() {
      return nameFilter(this.item?.item?.name)
    },
  },
  methods: {
    emit(event) {
      this.$emit(event)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../styles/style.scss';

.action {
  padding: 0.2em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@each $rarity, $color in $rarities {
  .border--#{$rarity} {
    border-left: solid 2px $color;
  }
}
.item {
  cursor: pointer;
  user-select: none;
  display: flex;
  background-color: #1c2028;
  align-items: center;
  justify-content: center;
  padding: 1em;

  &__img {
    max-height: 90px;
    &:hover {
      animation: bounce 4s ease-in-out 1s infinite alternate;
    }
    -webkit-user-drag: none;
  }
  &__image {
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px dashed #2d3139;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px) translateX(1px);
  }
  100% {
    transform: translateY(10px);
  }
}
</style>
