<template>
  <div class="store-detail mt-5">
    <div class="pointer go-back mb-4 pl-1" @click="$router.push('/store')">
      <fa :icon="['fa', 'chevron-left']" />
      <span class="pl-2">返回商城首页</span>
    </div>
    <div class="store-detail__wrapper" v-if="!loading">
      <header class="grid-a p-4">
        <h3 class="text-white">{{ nameObject.type }}{{ nameObject.name }}</h3>
        <!-- <h5 class="text-secondary">{{ nameObject.wear }}</h5> -->
        <h5 class="text-secondary">
          游戏类型丨
          <span class="text-primary">{{ game }}</span>
        </h5>
      </header>
      <section class="grid-b store-detail__img p-4">
        <div>
          <weapon-img shadow-size="lg" :src="imageUrl" alt="" />
        </div>
      </section>
      <div class="grid-c p-4">
        <div class="p-4 d-flex flex-column align-items-start" style="border-radius:6px;background:#141a24">
          <div class="d-flex text-secondary justify-content-between w-full">
            <div>
              外观丨<span class="text-white">{{ nameObject.wear }}</span>
            </div>
            <div>
              品质丨<span
                class=""
                :style="{
                  color: rarityColor,
                }"
                >{{ rarityName }}</span
              >
            </div>
            <div>
              类别丨<span class="text-white">{{ type }}</span>
            </div>
          </div>
          <divider />
          <div class="my-3 px-2">
            <div class="flex items-center">
              <div class="text-secondary text-11">待支付</div>
              <div class="text-white ml-3 text-14 bold">
                {{ $currencySign }} <span class="">{{ totalPrice }}</span>
              </div>
            </div>
            <divider />
            <div class="flex items-center">
              <div class="text-secondary text-10">在售价格</div>
              <div class="text-white ml-3 text-14 bold text-11">
                {{ $currencySign }} <span class="">{{ price }}</span>
              </div>
            </div>
            <div class="flex items-center">
              <div class="text-secondary text-10">购买手续费</div>
              <div class="text-white ml-3 text-14 bold text-11">
                {{ $currencySign }} <span class="">{{ surcharge }}</span>
              </div>
            </div>
          </div>
          <div v-if="!user.isLogin" v-b-tooltip.hover.top title="请先登录" style="width:auto">
            <btn class="btn-primary" @click="buy" :disabled="!user.isLogin">立即购买</btn>
          </div>
          <btn v-else class="btn-primary" @click="buy">立即购买</btn>
        </div>

        <!-- <text-drawer class="mt-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae aliquid nemo iste repudiandae consectetur.
          Cupiditate minima, voluptatum eaque temporibus fuga quos voluptatibus aut excepturi sequi est animi culpa
          aperiam iusto!
        </text-drawer> -->
      </div>
    </div>

    <div v-if="loading" class="store-detail__wrapper">
      <header class="grid-a p-4">
        <b-skeleton width="200px" height="45px" class="m-0"></b-skeleton>
        <b-skeleton width="150px" height="35px" class="m-0 mt-2"></b-skeleton>
      </header>
      <section class="grid-b store-detail__img p-4">
        <div class="d-flex justify-content-center items-center">
          <b-skeleton width="100%" height="380px" class="m-0 my-1"></b-skeleton>
        </div>
      </section>
      <div class="grid-c p-4">
        <div class="p-4 d-flex flex-column align-items-start" style="border-radius:6px;background:#141a24">
          <div class="d-flex text-secondary justify-content-between w-full">
            <b-skeleton width="120px" height="35px" class="m-0 my-1"></b-skeleton>
            <b-skeleton width="120px" height="35px" class="m-0 my-1"></b-skeleton>
            <b-skeleton width="120px" height="35px" class="m-0 my-1"></b-skeleton>
          </div>
          <divider />
          <div class="my-3 px-2">
            <b-skeleton width="140px" height="35px" class="m-0 my-1"></b-skeleton>
            <divider />
            <b-skeleton width="150px" height="35px" class="m-0 my-1"></b-skeleton>
            <b-skeleton width="110px" height="35px" class="m-0 my-1"></b-skeleton>
          </div>
          <btn class="btn-primary" disabled>立即购买</btn>
        </div>
      </div>
    </div>

    <b-modal id="purchase-confirm" title="购买" size="md" centered hide-footer @hidden="resetModal">
      <template #modal-title>
        <div style="position:absolute;left:50%;transform:translateX(-50%)">购买</div>
      </template>
      <div class="px-4 pb-4">
        <div class="order">
          <long-store-item :item="item" :price="totalPrice" />

          <div v-if="purchaseSuccess" class="mt-4 py-3 text-9 d-flex justify-content-center align-items-center">
            <tick-svg class="mr-2" />
            <span>购买成功！可在 <router-link to="/user/inventories">库存管理</router-link> 中查看</span>
          </div>
          <btn
            v-else
            class="btn pt-3 pb-3 btn-block btn-primary m-0 mt-4"
            :disabled="purchaseLoading"
            :loading="purchaseLoading"
            @click="purchase()"
          >
            确认购买
          </btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { StoreService } from '@/services/api'
import { ASSET_URL } from '@/config'
import { nameFilter } from '@/utils'
import { mapGetters } from 'vuex'
import LongStoreItem from './LongStoreItem.vue'
import TickSvg from '../../components/svgs/TickSvg.vue'

export default {
  name: 'StoreDetail',
  components: {
    LongStoreItem,
    TickSvg,
  },
  data() {
    return {
      item: {},
      loading: false,
      purchaseLoading: false,
      purchaseSuccess: false,
    }
  },
  computed: {
    hashName() {
      return this.$router.currentRoute.params.id
    },
    price() {
      if (!this.item.price) return 0
      return parseFloat(this.item.price)
    },
    surcharge() {
      if (!this.user || !this.user.info) return 0
      return ((this.price * this.user.info.store_surcharge_rate) / 100).toFixed(2)
    },
    totalPrice() {
      if (!this.user || !this.user.info) return 0
      return (this.price + (this.price * this.user.info.store_surcharge_rate) / 100).toFixed(2)
    },
    imageUrl() {
      if (!this.item.item) return ''
      return ASSET_URL + this.item.item.image_url
    },
    type() {
      if (!this.item || !this.item.item) return ''
      return this.item.item.type
    },
    rarity() {
      if (!this.item || !this.item.item) return 'grey'
      return this.item.item.rarity
    },
    rarityName() {
      /**
       *  'blue': 军规级,
          'grey': 消费级,
          'lightblue': 工业级,
          'orange': 违禁,
          'pink': 保密级,
          'purple': 受限级,
          'red': 隐秘,
          'yellow': 违禁,
       */
      switch (this.rarity) {
        case 'blue':
          return '军规级'
        case 'grey':
          return '消费级'
        case 'lightblue':
          return '工业级'
        case 'pink':
          return '保密级'
        case 'purple':
          return '受限级'
        case 'red':
          return '隐秘'
        case 'yellow':
          return '违禁'
        case 'orange':
          return '违禁'
        default:
          return '消费级'
      }
    },
    rarityColor() {
      /**
       *  'blue': #2d62d1,
          'grey': #ddd,
          'lightblue': #63a5d1,
          'orange': #fb8b32,
          'pink': #de38e4,
          'purple': #7839ed,
          'red': #e23e1a,
          'yellow': #fec41c,
       */
      switch (this.rarity) {
        case 'blue':
          return '#2d62d1'
        case 'grey':
          return '#ddd'
        case 'lightblue':
          return '#63a5d1'
        case 'orange':
          return '#fb8b32'
        case 'pink':
          return '#de38e4'
        case 'purple':
          return '#7839ed'
        case 'red':
          return '#e23e1a'
        case 'yellow':
          return '#fec41c'
        default:
          return '#ddd'
      }
    },
    game() {
      if (!this.item.item) return ''
      return this.item?.item?.app_id === 730 ? 'CS:GO' : 'DOTA2'
    },
    nameObject() {
      const name = this.item?.item?.name
      return nameFilter(name)
    },
    ...mapGetters(['user']),
  },
  mounted() {
    this.hydratePage()
  },
  methods: {
    toast(message, type = 'warning') {
      this.$bvToast.toast(message, {
        title: message,
        toaster: 'b-toaster-bottom-right',
        solid: true,
        variant: type,
      })
    },
    hydratePage() {
      this.loading = true
      StoreService.getStoreByHashName(this.hashName)
        .then(({ data }) => {
          this.item = data
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    async buy() {
      if (this.user.isLogin) {
        this.$bvModal.show('purchase-confirm')
      } else {
        this.toast('请先登录')
      }
    },
    async purchase() {
      try {
        this.purchaseLoading = true
        await StoreService.buyStoreItem(this.item.item.market_hash_name)
        // this.toast(this.item.item.name + ' 购买成功', 'success')
        // this.$bvModal.hide('purchase-confirm')
        this.purchaseSuccess = true
      } catch (e) {
        this.toast('购买失败！请确认你的交易链接设置正确或有足够余额')
      } finally {
        this.purchaseLoading = false
        this.$store.dispatch('LOAD_USER')
      }
    },
    resetModal() {
      this.purchaseSuccess = false
      this.purchaseLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.store-detail {
  max-width: 1200px;
  margin: 0 auto;
}

.store-detail__wrapper {
  background: rgba(37, 48, 67, 0.2);

  display: grid;
  grid-template-areas:
    'A B'
    'C B';
  grid-template-rows: min-content 1fr;
  @media (min-width: 1000px) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
  @media (max-width: 999px) {
    grid-template-areas:
      'A'
      'B'
      'C';
  }
}

.go-back {
  color: #aaa;
  &:hover {
    color: #fff;
  }
}

.store-detail__img {
  border-left: 1px dashed #666;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 999px) {
    border-left-color: transparent;
  }

  & > div {
    max-width: 450px;
    width: 100%;
  }

  img {
    /* pointer-events: none; */
  }
}

.grid-a {
  grid-area: A;
}
.grid-b {
  grid-area: B;
}
.grid-c {
  grid-area: C;
}
</style>
